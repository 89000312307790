import type { NextPage } from "next"
import Head from "next/head"
import { useRouter } from "next/router"
import strings from "../strings.json"

import Header from "../components/Header"

const Error404: NextPage = () => {
  const margins = "px-[24px] md:px-10"
  const { query } = useRouter()

  return (
    <div>
      <Head>
        <title>{strings.name}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={"This is the official mint page for " + strings.name + "."} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={strings.name} />
        <meta property="og:url" content="https://cockpunch.premint.xyz/" />
        <meta property="og:image" content="https://cockpunch.premint.xyz/meta-image.png" />
        <meta property="og:site_name" content={strings.name} />
        <meta property="og:description" content={"This is the official mint page for " + strings.name + "."} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={strings.name} />
        <meta name="twitter:description" content={"This is the official mint page for " + strings.name + "."} />
        <meta name="twitter:image:src" content="https://cockpunch.premint.xyz/meta-image.png" />
        <meta name="twitter:domain" content="https://cockpunch.premint.xyz/" />
      </Head>


      <Header hideConnect={true} />

      <div
        style={{
          textAlign: "center",
          margin: "100px auto"
        }}
      >
        Sorry, we couldn&rsquo;t find what you&rsquo;re looking for.
        <br />
        <br />
        <a
          className="cursor-pointer px-[16px] py-[10px] text-gray-100 bg-teal-dark rounded-xl text-sm"
          href="/"
        >
          Visit Home
        </a>
      </div>
    </div>
  )
}

export default Error404
